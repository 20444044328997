* {
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family:Arial, Helvetica, sans-serif;
 

}
body {
    background-color:cornsilk;
    overflow-x:hidden;

}
nav {
    height:250px;
    background-color:green;
}
.logo {
    height:240px;
    padding-left:50px;
}
h1 {
    margin:0;
    padding-top:250px;
    transform:translateY(-50%);
    font-style:italic;
}
.content {
    min-height:100vh;
}
.card, .card-title {
    background-color:rgba(252, 238, 185, 0.75);
    font-size:1.25em;
    display:inline-block;
}

.card {
    width: 100%;
}
h2 {
    font-size:2em;
    margin:0;
    padding:0;
    line-height:1em;
}
.page-footer {
    background-color:green;
}
.login {
    position:absolute;
    background-color:green;
    top:40%;
    left:50%;
    width:500px;
    transform:translate(-50%,-50%);
    padding:100px;
    border-radius:5%;
}
.index-footer {
    background-color:green;
    position:absolute;
    width:100%;
    top:100%;
    transform:translateY(-100%);
}
.login-img {
    width:300px;
    height:300px;  
}
.login button {
    margin-left:50%;
    transform:translateX(-50%);
}
.login input {
    background-color:white;
    color:white;

}
.admin-button {
    margin-left:20px;
}
.backtohome {
    margin-left:50%;
    transform:translateX(-50%);
}
.link {
    border: 1px solid green;
    display:block;
    position:relative;
    font-size:20px;
    height:42px;
    margin:10px;
    border-radius:5px;
    overflow-x:hidden;
}
.home-images {
    display:flex;
    justify-content:space-evenly;
    
}
.home-images img {
    width:31%;

}
.link button {
    left:100%;
    transform:translateX(-100%);
    position:absolute;
    margin-top:1px;
}
.link div {
    margin:5px;
}

@media only screen and (max-width: 999px) {
    .logo {
       filter:brightness(90%);
    }
}